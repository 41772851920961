import React, { useEffect, useState } from "react";
import MyMDXFile from "../../content/using-your-appraisal.mdx";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, useStaticQuery } from "gatsby";
import TableOfContents from "../../components/manual/TableOfContents";
import { graphql } from "gatsby";
import {
  components,
} from "../../components/manual/customMdx";
import { Container } from "@mui/material";

const Blog2 = () => {
  const post_data = useStaticQuery(graphql`
    query Blog2TOC {
      mdx(frontmatter: { slug: { eq: "using-your-appraisal" } }) {
        tableOfContents
      }
    }
  `);

  const [page_data, set_page_data] = useState<unknown | undefined>();

  useEffect(() => {
    set_page_data(post_data.mdx.tableOfContents);
  }, [post_data]);

  return (
    <Header_Layout>
      <Container sx={{ pb: 4, fontSize: 20 }}>
        <>
          {page_data && <TableOfContents items={page_data.items} />}
          <MyMDXFile components={components} />
        </>
      </Container>
    </Header_Layout>
  );
};

export default Blog2;

export const Head: HeadFC = () => (
  <title>Getting the Most out of Your Appraisal - BitLot.app</title>
);
