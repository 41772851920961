/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "How you can get the most out of your BitLot Appraisal"), "\n", React.createElement(_components.p, null, "So you're trying to sell your PC component, such as a graphics card or your whole PC. You've purchased enough Appraisal Tokens to appraise your part or parts, and we've gotten back to you with your valuation, advanced insights, and a shareable link."), "\n", React.createElement(_components.p, null, "Now what?"), "\n", React.createElement(_components.h2, null, "Put your PC out there!"), "\n", React.createElement(_components.p, null, "There are multiple ways to go about selling your PC, and your appraisal is designed to expedite this process. The first question for yourself should be: are you interested in shipping your PC, or would you prefer to do a direct transfer?"), "\n", React.createElement(_components.h2, null, "Sell your PC locally"), "\n", React.createElement(_components.p, null, "We recommend sending your link to friends and family (blast your Instagram, Facebook, email, etc.), as well as posting on Facebook Marketplace and Craigslist."), "\n", React.createElement(_components.h2, null, "Sell your PC nationally or globally"), "\n", React.createElement(_components.p, null, "For this, the previous methods work, too, but we also recommend posting on eBay. Also, our small but growing ", React.createElement("a", {
    style: {
      color: '#39B54A'
    },
    href: "https://discord.gg/9Zp7pqZKK4"
  }, "Discord server"), " has a channel for putting your builds as well."), "\n", React.createElement(_components.h2, null, "Utilizing your shareable BitLot appraisal"), "\n", React.createElement(_components.p, null, "First, go to your build and click on the SHARE button to copy your shareable link to your clipboard. Then, we recommend putting your link in the body of your email right after you mention your build or in the description(s) of your online listings. We suggest putting less rather than more for description; aside from obvious quirks or caveats, let your components speak for themselves!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
