import React from "react";

function getAnchor(text: string) {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-z0-9-]/g, "");
}

const H1 = ({ children }: { children?: string }) => {
  const anchor = getAnchor(children ?? '');
  const link = `#${anchor}`;
  return (
    <h1 id={anchor} style={{ padding: "1rem 0" }}>
      <a href={link} style={{ display: "none" }} className="anchor-link">
        §
      </a>
      {children}
    </h1>
  );
};

export default H1;
